// =================================================================
// This file is dynamically generated by gulp-compass-imagehelper
// Do NOT edit!
// =================================================================

// Returns if the image is accessible for these image-helpers
// Note: Not part of the official compass functions, but still useful
@function image-exists($image) {
  @return map-has-key($compass-imagehelper-imagesmap, $image);
}

// Returns the width of the image found at the path supplied by $image relative to your project's images directory.
@function image-width($image) {
  @if image-exists($image) {
    $list: map-get($compass-imagehelper-imagesmap, $image);
    $width: unquote(map-get($list, 'width'));
    @if ($width and str-length($width) > 0) {
      @return number($width) * 1px;
    }
  }
  @warn "Image '#{$image}' not found.";
  @return null;
}

// Returns the height of the image found at the path supplied by $image relative to your project's images directory.
@function image-height($image) {
  @if image-exists($image) {
    $list: map-get($compass-imagehelper-imagesmap, $image);
    $height: unquote(map-get($list, 'height'));
    @if ($height and str-length($height) > 0) {
      @return number($height) * 1px;
    }
  }
  @warn "Image '#{$image}' not found.";
  @return null;
}

// Generates a path to an asset found relative to the project's images directory.
// Passing a true value as the second argument will cause only the path to be returned instead of a url() function
// The third argument is used to control the cache buster on a per-use basis. When set to false no cache buster will be used.
// When true a md5-hash of the file is appended to the url. When a string, that value will be used as the cache buster.
@function image-url($image, $only-path: false, $cache-buster: false) {
  $result: $compass-imagehelper-path-prefix + $image;

  @if not(image-exists($image)) {
    @warn "Image '#{$image}' not found.";
  } @else {
    @if (type-of($cache-buster) == string) {
      $result: $result + '?' + $cache-buster;
    }

    @if ($cache-buster == true) {
      $list: map-get($compass-imagehelper-imagesmap, $image);
      $hash: map-get($list, 'hash');
      $result: $result + '?' + $hash;
    }
  }

  $result: quote($result);

  @if ($only-path == false) {
    $result: url($result);
  }

  @return $result;
}

// Two helper functions for casting string to number by Hugo Giraudel
// http://hugogiraudel.com/2014/01/15/sass-string-to-number/
// http://sassmeister.com/gist/9647408
@function _length($number, $unit) {
  $strings: 'px' 'cm' 'mm' '%' 'ch' 'pica' 'in' 'em' 'rem' 'pt' 'pc' 'ex' 'vw' 'vh' 'vmin' 'vmax';
  $units: 1px 1cm 1mm 1% 1ch 1 pica 1in 1em 1rem 1pt 1pc 1ex 1vw 1vh 1vmin 1vmax;
  $index: index($strings, $unit);

  @if not ($index) {
    @warn "Unknown unit `#{$unit}`.";
    @return false;
  }

  @return $number * nth($units, $index);
}

@function number($string) {
  // Matrices
  $strings: '0' '1' '2' '3' '4' '5' '6' '7' '8' '9';
  $numbers: 0 1 2 3 4 5 6 7 8 9;

  // Result
  $result: 0;
  $divider: 0;
  $minus: false;

  // Looping through all characters
  @for $i from 1 through str-length($string) {
    $character: str-slice($string, $i, $i);
    $index: index($strings, $character);

    @if $character == '-' {
      $minus: true;
    } @else if $character == '.' {
      $divider: 1;
    } @else {
      @if not ($index) {
        $result: if($minus, $result * -1, $result);
        @return _length($result, str-slice($string, $i));
      }

      $number: nth($numbers, $index);

      @if $divider == 0 {
        $result: $result * 10;
      } @else {
        // Move the decimal dot to the left
        $divider: $divider * 10;
        $number: $number / $divider;
      }

      $result: $result + $number;
    }
  }

  @return if($minus, $result * -1, $result);
}

$compass-imagehelper-path-prefix: '/img/';

$compass-imagehelper-imagesmap: (
  'cover.png': (
    width: '1600',
    height: '604',
    hash: '82d2f61c1448b310d9e0942ad0b87d46'
  ),
  'MadeInItaly-bollo.png': (
    width: '113',
    height: '112',
    hash: 'b25c44697c9215098a28e93b7b4f42ca'
  ),
  'next.png': (
    width: '30',
    height: '49',
    hash: '2b6b79a53729a3517911d74d3f6dff9c'
  ),
  'Orion_Home_Nastro.png': (
    width: '1000',
    height: '192',
    hash: '33f1dcbd826b495904de369e81d5af06'
  ),
  'Orion_VeganFriendly_Logo.png': (
    width: '400',
    height: '400',
    hash: 'ccd78d132ed162f9251dc77225834a94'
  ),
  'pin.png': (
    width: '34',
    height: '50',
    hash: 'bbb9e162e873f547b783fe83601b0eaf'
  ),
  'prev.png': (
    width: '30',
    height: '49',
    hash: '59048fe047d5038e4789939d542a5b8a'
  ),
  'Skyrunning-CONI.jpg': (
    width: '2045',
    height: '1194',
    hash: 'fb1f8c5dbd43a1b9894273e5ad92196e'
  ),
  'square_base.png': (
    width: '1',
    height: '1',
    hash: '5df0ac2d51cfecbde35e8dd1ba3a8d77'
  ),
  'updateprogress.gif': (
    width: '48',
    height: '48',
    hash: '4801d074dbe5cdd7755af9e25de1dc6c'
  ),
  'user-female.png': (
    width: '300',
    height: '300',
    hash: '25be2d0cb75e32c7c67bbcb3401fde67'
  ),
  'user-male.png': (
    width: '300',
    height: '300',
    hash: '8a0655000d0cbf72768ae2d5f0df0d33'
  ),
  'user-unisex.png': (
    width: '300',
    height: '300',
    hash: '222717c7222d38d139e26aebeab1948a'
  ),
  'white_base.png': (
    width: '400',
    height: '300',
    hash: 'c86d3fcc5ebc4bb7d3981846f8b4820b'
  ),
);
