/*Mixin - non modificare*/
/*Per mixin gia' pronti consultare www.compass-style.org*/

/*#region MENU */

//style basic menu
@mixin custom-menu {
    @if $background-extended == true {
        background-color: $topbar-background;
    }

    .menu:not(.vertical) {
        align-items: center;
    }

    .menu:not(.icons) {
        background-color: transparent;
        //anchor and icon general style
        a, i {
            text-transform: $menu-item-text-transform;
            font-weight: $menu-item-font-weight;
            padding: $menu-item-padding;

            @include text-styling($menu-item-color, $menu-item-background);
        }

        a {
            font-size: $menu-item-font-size;
            margin: $menu-item-margin;

            &:not(.button) {
                @if $menu-item-hover-underline == true {
                    border-bottom: 1px solid transparent;
                }
            }
        }

        i {
            font-size: calc(#{$menu-item-font-size} + 30%);
            margin: 0 !important;
        }

        .button {
            background-color: transparent;
        }

        li {
            &:last-child a {
                margin-right: 0;
            }
            //hover style
            &:hover, &:focus {
                a, i {

                    @include text-styling( $menu-item-color-hover,$menu-item-background-hover);
                }


                > a:not(.button) {
                    @if $menu-item-hover-underline == true {
                        border-bottom: 1px solid $menu-item-color-hover;
                    }
                }
            }
            //active style
            &.active {

                a {
                    font-weight: $menu-item-font-weight-active;
                    @include text-styling($menu-item-color-active, $menu-item-background-active);



                    @if $menu-item-active-underline == true {
                        border-bottom: 1px solid $menu-item-color-active;
                    }
                }
                //active-hover style
                &:hover {
                    a {
                        @include text-styling($menu-item-color-active-hover,$menu-item-background-active-hover);
                    }
                }
            }
        }
    }
}

//style dropdown menu (medium-large)
@mixin dropdown-medium-large {
    .menu.dropdown {
        > ul {
            z-index: 99999999;
        }
        //parent
        .is-dropdown-submenu-parent {
            > a {
                padding-right: 1.2rem;

                @if $menu-item-hover-underline == true {
                    border-bottom: 1px solid transparent !important;
                }

                @if $dropdown-parent-item-arrows == false {
                    padding: inherit;

                    &::after {
                        content: none;
                    }
                }
                @else {
                    margin-right: 0;

                    &::after {
                        border-color: $menu-item-color transparent transparent !important;
                    }
                }
            }
        }
        //dropdown items
        .is-dropdown-submenu {
            min-width: $dropdown-min-width;
            z-index: 9999999;
            background-color: $dropdown-background;
            border: $dropdown-border;
            top: $dropdown-position-y !important;
            right: $dropdown-position-x !important;



            li {
                a {
                    font-size: $dropdown-font-size;
                    padding: $dropdown-item-padding;
                    margin: $dropdown-item-margin;
                    display: inline-block;
                    @include text-styling($dropdown-item-color, $dropdown-item-background-color);
                }

                &:hover {
                    a {
                        @include text-styling($dropdown-item-color-hover, $dropdown-item-background-hover);
                    }
                }

                &.active {
                    a {
                        @include text-styling($dropdown-item-color-active, $dropdown-item-background-active);
                    }

                    &:hover {
                        a {
                            color: $dropdown-item-color-active-hover;
                            background-color: $dropdown-item-background-active-hover;
                        }
                    }
                }
            }

            @if $dropdown-item-arrows== true {


                &:before {
                    @include css-triangle(rem-calc(12), $dropdown-background, up);
                    top: -rem-calc(8);
                    right: rem-calc(6);
                    position: absolute;
                }
            }

            @if $dropdown-shadow== true {
                @include box-shadow($dark-gray 0 3px 5px);
            }
        }
    }
}

//style dropdown menu (small)
@mixin dropdown-small {


    .title-bar {
        height: $titlebar-height;
        padding: $titlebar-padding;
        background-color: $titlebar-background;

        .title-bar-right {
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

            .title-bar-title {
                line-height: $titlebar-height;
                text-transform: uppercase;
                font-size: 85%;
                color: $titlebar-icon-color;
                transition: all .6s;
            }

            .menu-icon {
                @include hamburger($titlebar-icon-color, $titlebar-icon-color);
                outline: none;
            }

            &:hover {
                cursor: pointer;
            }

            @include hamburger-x-animation($color-hamburger: $titlebar-icon-color, $color-x:$titlebar-icon-color-expand);
        }

        &:not(.expand) {
            .title-bar-right:hover {

                .title-bar-title {
                    color: $titlebar-icon-color-hover;
                }

                .menu-icon {
                    @include hamburger($titlebar-icon-color-hover, $titlebar-icon-color-hover);
                    outline: none;
                }
            }
        }

        &.expand {
            background-color: $titlebar-expand-background;

            .title-bar-title {
                color: $titlebar-icon-color-expand;
            }

            .menu-icon {
                @include hamburger($titlebar-icon-color-expand, $titlebar-icon-color-expand);
            }
        }
    }

    .top-bar {
        @include custom-menu;
        padding: $dropdow-padding;
        background-color: $dropdown-background !important;

        ul, ul ul {
            background-color: $dropdown-background;
        }

        a {
            display: $dropdown-item-display;
        }


        @if $menu-overlap == true {
            position: absolute;
            z-index: 999999999999;
            width: 100%;
        }
    }

    .icons {
        text-align: center;

        a {
            padding: $menu-icons-padding !important;
            margin: $menu-icons-margin;
            @include text-styling($menu-icons-color, $menu-icons-background);
        }

        i {
            font-size: $menu-icons-size !important;
            padding: 0 !important;
            margin: 0 !important;
            @include text-styling(inherit, inherit);
        }

        a:hover {

            @include text-styling($menu-icons-color-hover, $menu-icons-background-hover);

            i {
                @include text-styling(inherit, inherit);
            }
        }
    }
}

//style search
@mixin custom-search {

    input, a.button {
        border-radius: 0;
        height: $search-height;
    }

    input {
        font-size: $search-font-size;
        display: inline;
        padding: rem-calc(5 10);

        @if $search-show-input-border== false {
            width: calc(100% - 55px);
            border: none !important;
            border-bottom: 1px solid $extradark-gray !important;
            box-shadow: none !important;
        }
    }

    a.button {
        margin: 0;
        border: none;
        color: $search-icon-color !important;
        background-color: $search-icon-background !important;
        padding: $search-icon-padding !important;

        &:hover {
            color: $search-icon-color-hover !important;
            background-color: $search-icon-background-hover !important;
        }

        i {
            font-size: $search-icon-size;
        }
    }

    &.menu {
        padding: $menu-item-padding;
    }
}

/*#endregion */

/*#region CUSTOM BUILD BLOCK */

//style breadcrumbs
@mixin custom-breadcrumbs {
    background-color: $breadcrumbs-bg;
    margin: $breadcrumbs-margin;
    padding: $breadcrumbs-padding;
    color: $breadcrumbs-font-color;

    span {
        font-size: $breadcrumbs-font-size;
    }

    ul {
        margin-bottom: 0;
        display: inline-block;
        vertical-align: middle;
    }

    li {
        padding: rem-calc(2);
        font-weight: $normal;


        &:not(:last-child)::after {
            color: $breadcrumbs-font-color;
            line-height: 1;
        }

        &:last-child {
            color: $breadcrumbs-current-item-color;
        }

        a {
            color: $breadcrumbs-anchor-color;
        }
    }
}

//style accordion with folder/file icons
@mixin custom-accordion {

    * {
        transition: unset;
        transition: background .2s;
    }

    a {
        text-align: left;
        border-radius: 0;
        padding: 1rem;
    }
    // background for main accordion items
    .is-accordion-submenu-parent {
        a {
            background: $accordion-bg;
        }

        &:hover > a {
            background-color: scale-color( $accordion-bg, $lightness: 5%);
        }
    }
    // background for sub accordion items
    .is-accordion-submenu a {
        background: $accordion-sub-bg;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .is-accordion-submenu-item {
        border-bottom: 1px solid $accordion-separator;

        &:hover a {
            background-color: scale-color( $accordion-sub-bg, $lightness: 5%);
        }
    }
    //icon
    %accordion-icon {
        font-family: FontAwesome;
        margin-right: 1rem;
        color: $accordion-icon-color;
    }

    ul[data-accordion-menu] {

        a {
            color: $accordion-font-color;
            // sets accodion item separator color
            &::after {
                // sets accodion arrow color and direction
                border-color: $accordion-icon-color transparent transparent;
            }
        }

        .is-accordion-submenu-parent {
            // folder icon open
            &[aria-expanded="true"] > a {
                &::before {
                    @extend %accordion-icon;
                    content: "\f07c";
                }
            }
            // folder icon closed
            &[aria-expanded="false"] > a {
                &::before {
                    @extend %accordion-icon;
                    content: "\f07b";
                }
            }
            /*// file icon
            .is-accordion-submenu .is-submenu-item a::before {
                @extend %accordion-icon;
                content: "\f016";
            }*/
        }
        //nested elements
        .sublevel {
            text-indent: 1.3rem;
        }
    }
}

/*#endregion */

/*#region ANIMATION */

//animate from hamburger menu to X
@mixin hamburger-x-animation($speed: 0.6s, $color-hamburger: $medium-gray, $color-x: $medium-gray) {
    .menu-icon {


        &:after, &:before {
            content: '';
            transition: all $speed;
            width: 100%;
            height: 2px;
            display: block;
            background: $color-hamburger;
        }

        &.hamburger-x {


            &:before {

                @include rotate(-45deg);
                position: absolute;
                left: 0;
                background: $color-x;
            }

            &:after {
                @include rotate(45deg);
            }


            &::after, &:hover::after {
                box-shadow: none;
            }

            &::after, &::before {
                top: rem-calc(7);
            }
        }
    }
}

/*#endregion */

/*#region SCROLL DOWN/UP */

@mixin scroll-up {
    /*position: absolute;
    top: 5px;
    right: 10px;*/
    width: rem-calc(36);
    height: rem-calc(36);
    box-sizing: border-box;
    cursor: pointer;
    background: $buttonUp-background;
    opacity: 0.6;
    border-radius: 30%;
    transition: opacity 1s;
    z-index: 9999;


    &::after {
        position: absolute;
        top: 70%;
        left: 50%;
        content: '';
        width: rem-calc(16);
        height: rem-calc(16);
        margin: rem-calc(-12 0 0 -8);
        border-right: 2px solid $buttonUp-color;
        border-top: 2px solid $buttonUp-color;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        box-sizing: border-box;
    }


    &:hover {
        opacity: 1;

        &::after {
            border-right: 2px solid $buttonUp-color-hover;
            border-top: 2px solid $buttonUp-color-hover;
            /*-webkit-animation: pulse 1s infinite;
            animation: pulse 1s infinite;*/
        }
    }
}


/*#endregion */

/*#region UTILITIES */

//get a round image with given dimension
@mixin circleAvatar($pixel) {
    width: $pixel;
    height: $pixel;
    border-radius: 50%;
    border: 2px solid darkgrey !important;
}

//get header size (pass h1, h2 etc) - helpful to style element like h
@mixin header-size($type) {
    font-size: rem-calc(map-get(map-get(map-get($header-styles, "small"), $type), "font-size") * 1px);

    @include breakpoint(medium) {
        font-size: rem-calc(map-get(map-get(map-get($header-styles, "medium"), $type), "font-size") * 1px);
    }
}

//to move an object
@mixin position($position: relative, $top: auto, $right: auto, $bottom: auto, $left: auto, $center: false) {
    position: $position;

    @if $top != auto {
        top: $top;
    }

    @if $right != auto {
        right: $right;
    }

    @if $bottom != auto {
        bottom: $bottom;
    }

    @if $left != auto {
        left: $left;
    }


    @if $center == true {
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}

//to style a text
@mixin text-styling($color, $background) {
    color: $color;
    background-color: $background;
}

//map-value responsive
@mixin get-map-value($rule, $param) {
    #{$rule}: rem-calc(map-get($param, small));

    @include breakpoint(medium) {
        #{$rule}: rem-calc(map-get($param, medium));
    }
}

//em-calc
@function em-calc($values) {
    @return -zf-bp-to-em($values)
}

//pseudo
@mixin pseudo($display: block, $pos: absolute, $content: '') {
    content: $content;
    display: $display;
    position: $pos;
}

//image fixed ratio
@mixin image-fixed-ratio($width, $height, $fit: contain) {
    position: relative;
    display: block;

    @if($width != 0) {
        &:before {
            content: "";
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: ($height / $width) * 100%;
        }

        img {
            object-fit: $fit;
            height: 100%;
            @include position($position:absolute, $top:0, $left:0);
        }
    }
    @else {

        &:before {
            content: unset;
        }

        img {
            object-fit: unset;
            position: unset;
        }
    }
}


/*#endregion */


/*#region GRID */

//edit "../bower_components/foundation-sites/scss/xy-grid/layout" to add $gutters param
@mixin custom-block-grid($cell-number: 3, $gutter: 5px) {

    @include xy-gutters($gutters:$gutter,$negative:true);
    @include xy-grid-layout($n:$cell-number, $gutters:$gutter,$gutter-type:margin, $gutter-position:top bottom left right);
}
/*#endregion */