@-webkit-keyframes scrolling {
    50% {
        height: rem-calc(15);
    }
}

@keyframes scrolling {

    50% {
        height: rem-calc(15);
    }
}

@-webkit-keyframes pulse {

    50% {
        top: 60%;
    }
}

@keyframes pulse {

    50% {
        top: 60%;
    }
}


@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


@keyframes highlight {
    from {
        background: $primary-color;
        color: white;
    }

    to {
        color: initial;
        background: transparent;
    }
}
