﻿/* #region ARTICLE(S)*/

.article {

    #pnlAlbum {
        margin-bottom: 15px;
    }

    #pnlRightCol {
        @include breakpoint(small only) {
            border: 1px solid $secondary-color;
            padding: 0px 10px;
            margin-bottom: 10px;
            font-size: 90%;
        }

        #imgPublisherLogo {
            margin-left: 25px;
            max-width: 110px;
        }
    }
}

/*#endregion */

/*#region EVENT (S) */

.event {

    #pnlDate, #pnlCountdown {
        padding: 10px 0;
        display: flex;
        align-items: center;

        i {
            margin-right: 15px;
        }

        .date {
            font-size: rem-calc(18);
        }

        .timeValue {
            font-size: rem-calc(15);
            display: block;
            color: scale-color($body-font-color, $lightness: 50%);
        }
    }

    #pnlDate {
        padding-top: 0;
    }
}

/*#endregion */

/*#region PERSON(S) */

.person {

    @include breakpoint(small only) {
        font-size: 90%;
        margin-bottom: 10px;
    }

    #pnlRightCol {
        i[class*='fa-'] {
            width: 25px;
            vertical-align: baseline;
            margin-bottom: 10px;
        }
    }

    a {
        i[class*='fa-'] {
            color: $anchor-color;
        }

        &:hover [class*='fa-'] {
            color: $anchor-color-hover;
        }
    }

    .fa-calendar {
        margin-right: 7px;
    }
}

/*#endregion */

/*#region SERP */

.serp {
    .sticky-menu {
        transition: unset;

        * {
            transition: inherit;
        }

        @extend %responsive-padding;


        @include breakpoint(medium) {
            font-size: rem-calc(20);
        }

        padding-top: 10px;

        .menu {
            li {
                margin: 4px 0;

                &:first-child {
                    margin-bottom: 10px;
                }
            }

            a {
                padding-top: 6px;
                padding-bottom: 6px;


                &.is-active {
                    background-color: $primary-color;
                    color: $white;
                }
            }
        }
    }

    h5 {
        text-align: left;
        font-weight: $normal;
        font-size: rem-calc(30);
    }

    .panel {
        padding: 30px 0;

        @include breakpoint(medium) {
            &:first-child {
                padding: 0;
            }
        }
    }

    .grid-x[class*="-up-"] {
        @include flex-align($x:left);
    }

    .results {
        .panel:not(:last-child) {
            border-bottom: 1px solid $medium-gray;
        }
    }
}


/*#endregion */

/*#region ERROR */

.error {


    h1 {
        text-align: center;
        font-size: rem-calc(70);

        @include breakpoint(medium) {
            font-size: rem-calc(100);
        }
    }

    .text-large {
        margin-bottom: 4px;
        font-size: rem-calc(24);

        @include breakpoint(medium) {
            font-size: rem-calc(30);
        }
    }

    .text-small {
        font-size: rem-calc(16);
    }




    #pnlSolutions {
        font-size: rem-calc(20);
        margin: 40px 0;

        p {
            margin-bottom: 6px;
        }

        .button {
            margin: 6px;
            font-weight: $normal;

            @include breakpoint(medium) {
                font-size: rem-calc(20);
                margin: 10px;
            }
        }

        #lnkHome {
            i {
                color: inherit;
            }
        }

        i {
            padding-left: 5px;
            vertical-align: baseline;
        }
    }
}
/*#endregion */

/*#region JOB POSTING */

.jobPostings {
    #pnlBirthdate {
        label {
            line-height: 1;
        }

        .fa-asterisk {
            vertical-align: sub !important;
        }

        .not-mandatory {
            color: transparent !important;
        }
    }
}

.jobPostingsGrid {
    .date {
        display: block;
        font-size: rem-calc(15);
        color: scale-color($body-font-color, $lightness: 50%);
    }
}

.jobPosting {
    .row {
        margin-bottom: 18px;

        p, ul {
            margin-bottom: 0;
        }

        .bold {
            color: scale-color($body-font-color, $lightness: 40%);
            display: block;
            background-color: $light-gray;
            text-transform: uppercase;
            font-size: rem-calc(13);
            padding: 2px 5px;
            margin-left: -5px;
            margin-bottom: 2px;
            font-weight: $normal;
        }

        h3 {
            font-size: rem-calc(17);
            font-weight: $normal;
            margin: 10px 0 0;
        }
    }
}

.formApplication {
    #aspxChkSkils {


        label {
            width: calc(100% - 13px - 1.5rem);
        }
    }

    #pnlUpload {
        padding: 10px;
        border: 1px solid $medium-gray;
        margin-bottom: 40px;

        .token {
            padding: 5px;
            margin: 2px;
            border-radius: 7px;
        }

        table {
            margin-bottom: 0;

            tbody {
                tr {

                    &:nth-child(even) {
                        background-color: initial;
                    }
                }


                &:nth-child(odd) {
                    background-color: initial;
                }
            }

            td {
                padding: initial;
            }

            td a {
                padding: 10px;
                display: block;
            }
        }

        .button.secondary {
            font-size: rem-calc(18);
            border: 1px solid $medium-gray;
        }
    }


    #SubmitButton {
        padding: 4px 15px;
        font-size: rem-calc(16);
        margin-right: 0;
        float: right;
    }
}

/*#endregion */

/*custom*/
/*#region SUB NAV MENU */

#pnlSubNavMenu {
    margin-bottom: rem-calc(30);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    @include breakpoint(large) {
        justify-content: center;
        flex-wrap: nowrap;
    }

    .item {
        opacity: 0.4;
        max-width: calc(100% / 6);
        margin-right: 1px;

        &:last-child {
            margin-right: 0;
        }

        @include breakpoint(medium) {
            max-width: calc(100% / 9);
        }

        @include breakpoint(large) {
            max-width: unset;
        }

        &.active {
            opacity: 1;
        }

        &:hover {
            opacity: 1;
        }
    }
}

#subNavMenu {
    * {
        transition: all .2s;
    }

    .item a {
        opacity: 0.4;

        &.active {
            opacity: 1;
        }

        &:hover {
            opacity: 1;
            background-color: $primary-color;
        }
    }

    .gridItemTitle {
        font-size: rem-calc(14);
    }
}

.subNavMenu {
    position: relative;
    padding-top: rem-calc(40);

    &:before {
        /*@include pseudo();
        @include position($position:absolute, $top:0, $left:50%);
        transform: translateX(-50%);
        width: 10%;
        height: 1px;
        border-top: 1px dashed $dark-gray;*/
    }
}

/*#endregion */

.readMore.arrow {
    margin: 0;
    position: relative;
    display: block;
    border: unset;
    width: 100%;
    font-size: rem-calc(10);
    margin-top: rem-calc(20);

    &:before {
        @include pseudo();
        // @include position($position:absolute, $bottom:105%, $left:50%);
        @include position($position:absolute, $bottom:100%, $left:50%);
        width: 15px;
        height: 15px;
        border-width: 3px;
        border-style: solid;
        border-color: transparent $primary-color $primary-color transparent;
        transform: rotate(45deg) translateX(-50%);
    }
}

.products,
.product,
.tags,
.tag {
    h1 {
        @include breakpoint(medium) {
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            margin: rem-calc(13 0);

            &:after {
                @include pseudo($display:inline-block,$pos:relative);
                left: rem-calc(30);
                background: image-url("Orion_VeganFriendly_Logo.png");
                background-size: contain;
                background-repeat: no-repeat;
                z-index: 9;
                width: rem-calc(70);
                height: rem-calc(70);
            }
        }
    }
}

/*#region DEFAULT -> homepage */

.homepage {

    h5 {
        margin-bottom: rem-calc(10);

        .sans-serif {
            text-transform: uppercase;
            font-size: rem-calc(18);

            @include breakpoint(medium) {
                font-size: rem-calc(25);
            }
        }

        .cursive {
            font-size: rem-calc(55);

            @include breakpoint(medium) {
                font-size: rem-calc(70);
            }
        }
    }

    .readAll {
        margin: rem-calc(35) 0;
    }

    .homeSlider {
        margin-bottom: rem-calc(40);

        @include breakpoint(medium) {
            margin-bottom: rem-calc(60);
        }

        .slick-slide {
            @include breakpoint(small) {
                & > div {
                    @include image-fixed-ratio(4, 3, cover);

                    img {
                        object-position: 75% 50%;
                    }
                }
            }

            @include breakpoint(medium) {
                & > div {
                    @include image-fixed-ratio(16, 9, cover);

                    img {
                        object-position: 60% 50%;
                    }
                }
            }

            @include breakpoint(large) {
                & > div {
                    @include image-fixed-ratio(0,0);
                }
            }
        }

        .slideBackground {
            @include xy-grid-container();
            @include xy-cell();
            background-color: transparent;

            @include position($position:absolute, $bottom:2%, $left:50%);
            transform: translateX(-50%);
            width: auto;

            @include breakpoint(medium) {
                @include position($position:absolute,$bottom:5%, $top:10%);
                width: 100%;
            }

            &:before { /*logo vegan friendly*/
                @include breakpoint(medium) {
                    @include pseudo();
                    @include position($position:absolute, $bottom:0, $right:5%);
                    background-image: image-url("Orion_VeganFriendly_Logo.png");
                    width: rem-calc(100);
                    height: rem-calc(100);
                    z-index: 9;
                    background-size: contain;
                    background-repeat: no-repeat;
                }

                @include breakpoint($global-width) {

                    @include position($position:absolute, $bottom:0, $left:2%);
                    right: auto;
                    width: rem-calc(150);
                    height: rem-calc(150);
                }
            }

            p {
                text-align: center;
                display: inline-block;
                padding-left: rem-calc(20);

                @include breakpoint(small only) {
                    margin: 0;
                    text-shadow: 1px 1px 6px black;
                }
            }

            span {
                display: block;
            }

            .cursive {
                line-height: 1;
                font-size: rem-calc(50);

                @include breakpoint(medium) {
                    font-size: rem-calc(80);
                }

                @include breakpoint(large) {
                    font-size: rem-calc(130);
                }
            }

            .cursive + .cursive {
                margin-top: rem-calc(-20);
                font-size: rem-calc(60);

                @include breakpoint(medium) {
                    font-size: rem-calc(90);
                }

                @include breakpoint(large) {
                    font-size: rem-calc(140);
                }
            }

            .sans-serif {
                line-height: 1.1;
                text-transform: uppercase;
                font-weight: $light;
                font-size: rem-calc(16);

                @include breakpoint(medium) {
                    font-size: rem-calc(22);
                }

                @include breakpoint(large) {
                    font-size: rem-calc(35);
                }
            }
        }
    }

    #pnlProductsBrand {
        .brandCarousel {
            .productsGrid {
                margin: 0;

                .cell {
                    padding: rem-calc(8);
                }
            }
        }
    }

    #pnlProducts {
        background-color: $light-gray;
        padding: rem-calc(20) 0;

        .bottle {
            padding: rem-calc(30 25 40);
            margin-bottom: rem-calc(10);

            @include breakpoint(medium) {
                opacity: .5;
                padding: rem-calc(30 25 50);
            }

            @include breakpoint(large) {
                padding: rem-calc(10 25);
            }


            &:hover {
                opacity: 1;
                transform: scale(1.02);

                @include breakpoint(medium) {
                    transform: scale(1.1);
                }
            }
        }

        .readAll {
            margin-top: rem-calc(15);

            @include breakpoint(large) {
                margin-top: rem-calc(50);
            }
        }
    }

    #pnlWhereWho {
        .where,
        .who {
            padding-top: rem-calc(30);
        }

        .where {
            padding-bottom: rem-calc(30);

            img {
                margin-bottom: rem-calc(20);
                max-width: rem-calc(90);
            }
        }

        .thinkGreen {
            margin-top: rem-calc(10);

            @include breakpoint(medium) {
                margin-top: rem-calc(20);
            }

            img {
                max-width: rem-calc(90);
            }

            .button {
                background-color: $green;
            }
        }

        .who {
            background-color: $medium-gray;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            img {
                width: 100%;
            }
        }
    }
}
/*#endregion */

/*#region PRODUCTS -> brand/wines */
.productsGrid {
    text-align: center;

    .gridItemTitle {
        font-size: rem-calc(12);
        text-transform: uppercase;
    }

    .image-wrapper {
        position: relative;
    }

    .brand .readMore {
        margin: 0;
        @include position($position:absolute, $top:7%, $left:50%);
        transform: translateX(-50%);
    }

    .pnlParent {
        p {
            font-size: rem-calc(12);
            text-transform: uppercase;
            margin-bottom: 0;
            font-weight: $bold;
        }
    }

    .bottle {
        padding: rem-calc(5 0);

        .image-wrapper {
            margin-bottom: rem-calc(8);
        }

        .readMore {
            margin: 0;
            @include position($position:absolute, $top:108%, $left:50%);
            transform: translateX(-50%);
            border: unset;
            width: 100%;
            font-size: rem-calc(10);
            /*&:before {
                @include pseudo();
                @include position($position:absolute, $bottom:105%, $left:50%);
                width: 15px;
                height: 15px;
                border-width: 3px;
                border-style: solid;
                border-color: transparent $primary-color $primary-color transparent;
                transform: rotate(45deg) translateX(-50%);
            }*/
        }

        &:hover {
            transform: scale(1.02);

            @include breakpoint(medium) {
                transform: scale(1.1);
            }
        }
    }

    .pnlHover {
        opacity: 0;
        @include position($position:absolute, $top:0, $left:0);
        width: 100%;
        height: 100%;
    }

    .cell:hover {
        .pnlHover {
            opacity: 1;
        }
    }
}

.products {
    .productsGrid {
        margin-bottom: rem-calc(40);

        @include custom-block-grid($cell-number: 2, $gutter: 1px);


        @include breakpoint(medium) {
            @include custom-block-grid($cell-number: 4, $gutter: 1px);
        }

        @include breakpoint(large) {
            @include custom-block-grid($cell-number: 5, $gutter: 1px);
        }

        .cell {
            /*margin-bottom: 0;*/
        }
    }
}

.product {

    #pnlGalleryTop {
        @include breakpoint(small only) {
            text-align: center;

            img {
                max-width: rem-calc(150);
                margin: auto;
            }
        }
    }

    .productsGrid {
        justify-content: center;
        margin-bottom: rem-calc(60);
    }

    &.bottle-detail {
        #pnlPageTitleCustom {
            margin-bottom: 0;

            .flex {
                display: flex;
                flex-direction: column;
            }

            .parentTitle {
                font-size: rem-calc(20);
                font-weight: $bold;
            }

            .title {
                font-size: rem-calc(25);
            }
        }

        #pnlWineIntro {
            background-color: scale-color($dark-gray, $lightness:30%);
            padding-top: rem-calc(20);
            margin-bottom: rem-calc(50);
            background-size: 0 0;
            position: relative;
            z-index: 1;

            &:before {
                //sfondo pattern
                @include pseudo();
                @include position($position:absolute, $bottom:0, $left:0);
                width: 100%;
                height: 100%;
                background-image: inherit;
                background-size: cover;
                //opacity: 0.3;
                z-index: -1;
            }

            &:after {
                //sfondo shadow
                @include pseudo();
                @include position($position:absolute, $bottom:0, $left:0);
                @include filter-gradient(rgba($dark-gray, .8), rgba($dark-gray, .2), vertical); // IE6-9
                @include background(linear-gradient(to bottom, $dark-gray 0%,scale-color($dark-gray, $lightness:20%) 80%));
                width: 100%;
                height: 15%;
                z-index: -1;
            }

            .arrow-down {
                width: rem-calc(50);
                height: rem-calc(50);
                background: scale-color($dark-gray, $lightness:20%);
                @include position($position:absolute, $top:100%, $left:50%);
                transform: translate(-50%, -50%) rotate(45deg);
                z-index: -1;
            }

            #pnlImageBottle {
                background-size: 0 0;
                position: relative;
                padding-bottom: rem-calc(30);
                z-index: 1;

                &:before {
                    //shadow riflesso
                    @include pseudo();
                    @include position($position:absolute, $bottom:0, $left:0);
                    @include background(linear-gradient(to bottom, rgba(scale-color($dark-gray, $lightness:20%), 0) 0%,scale-color($dark-gray, $lightness:20%) 80%));
                    height: 8%;
                    width: 100%;
                    z-index: 1;
                }

                &:after {
                    //riflesso bottiglia
                    @include pseudo();
                    @include position($position:absolute, $bottom:0, $left:0);
                    width: 100%;
                    height: 10%;
                    background-image: inherit;
                    background-size: cover;
                    background-position: center bottom;
                    opacity: 0.2;
                    transform: scale(1,-1);
                    z-index: -1;

                    @include breakpoint(medium) {
                        height: 8%;
                    }
                }
            }

            .summary {
                font-size: rem-calc(18);
                color: white;
                display: flex;
                align-self: center;
            }
        }

        #pnlGalleryTop {
            img {
                max-width: unset;
            }

            .grid-x {
                @include custom-block-grid($cell-number: 1, $gutter: 1px);
                display: flex;
                justify-content: center;

                > .cell {
                    max-width: calc(100% / 3);
                    /*margin-bottom: 4px;*/
                }

                @include breakpoint(medium) {
                    justify-content: flex-start;

                    & > .cell {
                        max-width: calc(100% / 4);
                    }
                }

                @include breakpoint(large) {


                    & > .cell {
                        max-width: unset;
                    }
                }
            }

            a {
                @include image-fixed-ratio(1,1, cover);
            }
        }

        #pnlRightCol {
            padding-right: 1.5rem;
            padding-left: 1.5rem;
            text-align: center;

            @include breakpoint(small only) {
                margin-top: rem-calc(30);
            }

            a {
                text-transform: uppercase;
                font-weight: $normal; //$bold;
                font-size: rem-calc(12);
            }
        }

        #pnlTopic,
        #pnlDemands,
        #pnlWorks {
            @include breakpoint(small only) {
                .compositingImage,
                img {
                    max-width: rem-calc(150);
                    margin: auto;
                }
            }
        }

        #pnlDemands {
            margin-top: rem-calc(25);



            .compositingImage {
                position: relative;
                background-color: $primary-color;
            }

            img {
                padding: .5rem;

                &:not(:first-child) {
                    @include position($position:absolute, $top:0, $left:0);
                }

                &.hovered {
                    filter: brightness(150%);
                }
            }

            .readMore {
                // margin-top: rem-calc(35);
                a {
                    display: block;
                }
            }
        }

        #pnlWorks {
            margin-top: rem-calc(25);

            a {
                margin-bottom: rem-calc(10);
                display: block;

                .year {
                    font-weight: $bold;
                    font-size: rem-calc(14);
                }

                .contest {
                    font-size: rem-calc(12);
                }

                .medal {
                    display: block;
                    font-weight: $bold;
                }
            }
        }

        #pnlSubNavMenu {
            justify-content: center;

            img {
                max-height: rem-calc(150);
                margin-left: rem-calc(8);
                margin-right: rem-calc(8);
                padding-top: rem-calc(8);
                padding-bottom: rem-calc(8);
            }

            @include breakpoint(small only) {
                .item {
                    max-width: calc(100% / 4);
                }
            }
        }

        #pnlSharing {
            margin-top: rem-calc(50);
        }

        #pnlLibrary {
            margin-top: rem-calc(50);
        }
    }
}
/*#endregion */

/*#region DEMANDS -> vineyards */
.demandsGrid {
    .lnkItemWrapper {
        position: relative;
        display: block;
    }

    .image-wrapper {

        @include image-fixed-ratio(1,1, cover);
    }

    .pnlHover {
        opacity: 1;
        @include position($position:absolute, $top:0, $left:0);
        width: 100%;
        height: 100%;
        text-align: center;
        background-color: rgba($primary-color, .4);

        @include breakpoint(medium) {
            //opacity: 0;
            /*background-color: rgba($primary-color, .85);*/
            @include background(linear-gradient(to bottom, transparent 0%,transparent 60%, rgba($primary-color, .65) 100%));

            img {
                opacity: 0;
            }
        }

        img {

            @include position($position:absolute, $center:true);
            width: auto !important;
            height: 100%;
            padding: rem-calc(5 0 32);

            @include breakpoint(medium) {
                padding: rem-calc(20 0 50);
            }
        }

        .gridItemTitle {
            @include position($position:absolute, $left:50%, $bottom:5%);
            transform: translateX(-50%);
            color: white;
            text-transform: uppercase;
            font-size: rem-calc(14);
            width: 100%;

            @include breakpoint(medium) {
                font-size: rem-calc(16);
            }
        }
    }

    .cell {
        &:hover {
            .pnlHover {
                opacity: 1;
                background-color: rgba($primary-color,.85);

                img {
                    opacity: 1;
                }
            }
        }
    }
}

.demands {
    h5 {
        margin: rem-calc(40 0 10);
        color: $dark-gray;
    }

    .demandsGrid {
        margin-bottom: rem-calc(40);

        @include custom-block-grid($cell-number: 2, $gutter: 1px);


        @include breakpoint(medium) {
            @include custom-block-grid($cell-number: 3, $gutter: 1px);
        }

        @include breakpoint(large) {
            @include custom-block-grid($cell-number: 4, $gutter: 1px);
        }

        > .cell {
            /*margin-bottom: 0;*/
            position: relative;
        }
    }
}

.demand {
    #pnlAlbum {

        .grid-x {
            @include custom-block-grid($cell-number: 2, $gutter: 1px);


            @include breakpoint(medium) {
                @include custom-block-grid($cell-number: 4, $gutter: 1px);
            }

            position: relative;

            > .cell {
                /*margin-bottom: 0;*/
                flex-basis: calc(100% / 2);

                @include breakpoint(medium) {
                    flex-basis: calc(((100% - 15%) / 3) - 6px);
                }

                &:not(:nth-child(1)):not(:nth-child(2)) {
                    a {
                        cursor: unset;


                        @include image-fixed-ratio(8, 5, $fit:cover);
                    }
                }

                &:nth-child(1) {
                    background-color: $primary-color;
                    flex-basis: 30%;
                    display: flex;
                    align-items: center;

                    @include breakpoint(medium) {
                        flex-basis: 15%;
                    }
                }

                &:nth-child(2) {
                    @include position($position:absolute, $top:29%, $left:0);
                    transform: translateY(-50%);
                    width: 30%;



                    @include breakpoint(medium) {
                        top: 50%;
                        width: 15%;
                    }
                }

                &:nth-child(3) {
                    @include breakpoint(small only) {
                        flex-basis: 70%;
                    }
                }
            }
        }
    }

    .productsGrid {
        justify-content: center;
    }

    #pnlSubNavMenu {

        .item {
            position: relative;
            width: 100%;
            max-width: calc(100% / 4);

            @include breakpoint(medium) {
                max-width: calc(100% / 6);
            }

            @include breakpoint(large) {
                max-width: unset;
            }
        }

        .pnlHover {
            @include position($position:absolute, $top:0, $left:0);
            width: 100%;
            height: 100%;
            background-color: rgba($primary-color, 0.3);
            text-align: center;
            opacity: 1;

            @include breakpoint(medium) {
                opacity: 0;
                background-color: rgba($primary-color, 0.5);
            }

            &:hover {
                opacity: 1;
            }

            span {
                @include position($position:absolute, $center:true);
                display: block;
                color: white;
                font-size: rem-calc(12);
                text-transform: uppercase;
            }
        }

        .base {
            width: 100%;
        }

        img:not(.base) {
            @include position($position:absolute, $top:0, $left:0);
            object-fit: cover;
            height: 100%;
        }
    }
}
/*#endregion */

/*#region WORKS -> awards */
.works {
    #pnlAlbum {
        margin-bottom: rem-calc(50);
        // background-color: #E5E5E5;
        .cell {
            margin: 0;
        }
        /*.grid-x {
            @include xy-gutters($gutters:0);

            @include xy-grid-layout($n:3, $gutters:0);

            @include breakpoint(medium) {
                @include xy-grid-layout($n:6, $gutters:0);
            }

            position: relative;
        }*/
        img {
            margin: auto;
        }

        a {
            cursor: unset;
        }
    }

    .worksGrid {
        margin-top: rem-calc(40);
        margin-bottom: rem-calc(40);

        > .cell {
            @include get-map-value(margin-bottom, $grid-margin-gutters);
        }
    }
}

.work {
    #pnlAwards {
        margin-top: rem-calc(30);

        * {
            transition: unset;
        }
        /*.pnlImgContest {
            text-align: center;
            margin-bottom: rem-calc(10);
        }

        .imgContest {
            max-width: rem-calc(150);
        }*/
        /*.accordion {
            margin-bottom: rem-calc(50);

            &, .accordion-content {
                background-color: transparent;
                border: unset;
            }

            .accordion-title {
                margin-bottom: rem-calc(6);
                color: white;
                text-transform: uppercase;
                text-align: center;
                color: white;
                padding: rem-calc(10);
                font-size: rem-calc(16);
            }

            .accordion-content {
                // text-align: left;
                padding-left: rem-calc(30);

                @include breakpoint(medium) {
                    padding-left: rem-calc(70);
                }

                .cell {
                    margin: 0;
                }

                .item {
                    position: relative;
                    padding: rem-calc(10 0 10 40);
                    text-transform: uppercase;
                }

                .lnkDownload {
                    @include position($position:absolute, $left:0);
                    position: absolute;
                }
            }

            .gridItemTitle {
                font-size: rem-calc(14);
            }

            .gold {
                background-color: #ebae1a;
            }

            .silver {
                background-color: #b4b6b5;
            }

            .bronze {
                background-color: #aa501c;
            }

            .commended {
                background-color: #2064a5;
            }
        }*/
        .worksGrid {
            margin-bottom: rem-calc(20);
            margin-top: rem-calc(25);

            .cell {
            }

            .item {
                text-transform: uppercase;
                font-size: rem-calc(14);
                text-align: left;
                padding: rem-calc(5);
                margin-bottom: rem-calc(14);
                color: $anchor-color;

                a {
                    color: inherit;
                }

                .gridItemTitle {
                    font-size: inherit;
                }
            }

            .highlight .item {
                animation: highlight 3s ease-in-out;
            }
        }
    }
}

.worksGrid {
    @include xy-gutters($gutters:10);

    @include xy-grid-layout($n:3, $gutters:10);

    .cell {
        margin-bottom: rem-calc(10) !important;
    }

    .item {
        /*height: 100%;
        display: flex;
        justify-content: center;*/
        a {
            /*display: flex;
            align-items: center;
            justify-content: center;*/
            span {
                font-weight: 500;
                display: block;
            }
        }
    }
}
/*#endregion */

/* #region WHO WE ARE -> about us */
.whoWeAres {

    #pnlAlbum {

        .grid-x {
            @include custom-block-grid($cell-number: 2, $gutter: 1px);


            @include breakpoint(medium) {
                @include custom-block-grid($cell-number: 3, $gutter: 1px);
            }

            & > .cell {
                /*margin: 0;*/
            }

            .cell:first-child {
                @include breakpoint(small only) {
                    flex-basis: 100%;
                }
            }
        }

        a {
            cursor: unset;
        }
    }

    #pnlRightCol {
        background-color: $medium-gray;
        padding-top: rem-calc(20);
        padding-bottom: rem-calc(20);
        margin-top: rem-calc(20);

        @include breakpoint(medium) {
            margin-top: 0;
        }
    }
}
/*#endregion */

/* #region PERSONS -> winemaking team */
.persons {

    #pnlAlbum {

        .grid-x {
            /*@include xy-gutters($gutters:2px);

            @include xy-grid-layout($n:2, $gutters:2px);

            @include breakpoint(medium) {
                @include xy-gutters($gutters:14px);
                @include xy-grid-layout($n:4, $gutters:14px);
            }*/
            @include custom-block-grid($cell-number: 2, $gutter: 1px);


            @include breakpoint(medium) {
                @include custom-block-grid($cell-number: 4, $gutter: 1px);
            }

            & > .cell {
                /*margin: 0;*/
            }
            /*.cell:first-child {
                @include breakpoint(small only) {
                    flex-basis: 100%;
                }
            }*/
        }

        a {
            cursor: unset;
        }
    }
}
/*#endregion */

/*#region WEB PAGE -> Think Green */
.webPage {

    #pnlPageTitle {
        background-color: $green;
        color: white;
    }

    #pnlGalleryTop {
        position: relative;

        a {
            cursor: unset;
        }

        .grid-x {
            @include custom-block-grid($cell-number: 1, $gutter: 1px);
        }
        //height: 100%;
        *:not(p) {
            @include breakpoint(large) {
                // height: 100%;
            }
        }

        img {
            @include breakpoint(large) {
                // object-fit: cover;
            }
        }

        p {
            text-shadow: 1px 1px 3px $black;
            text-align: left; // center;
            color: white;
            @include position($position:absolute, $top:3%, $left:3%);


            @include breakpoint(small only) {
                /*transform: translateX(-50%);
                width: 100%*/
            }

            @include breakpoint(medium) {
                left: 5%;
            }





            span {
                display: block;
            }

            .sans-serif {
                font-size: rem-calc(16);
                text-transform: uppercase;

                @include breakpoint(medium) {
                    font-size: rem-calc(20);
                }
            }

            .cursive {
                font-size: rem-calc(60);
                margin-top: rem-calc(-14);

                @include breakpoint(medium) {
                    font-size: rem-calc(90);
                }
            }

            .move-up {
                margin-top: rem-calc(-14);

                @include breakpoint(medium) {
                    margin-top: rem-calc(-20);
                }
            }
        }
    }

    .veganLogo {
        max-width: 25%;
        //margin: auto;
        margin-bottom: rem-calc(30);
    }
}
/*#endregion */

/* #region WHERE WE ARE -> Contacts*/
.whereWeAre {
    legend, h3 {
        text-transform: uppercase;
        margin-bottom: rem-calc(20);
        font-size: rem-calc(22) !important;
    }

    .separator {
        border-left-color: $primary-color;
    }

    #requestInfoContactPage {

        @include breakpoint(small only) {
            margin-bottom: 20px;
        }

        fieldset {
            border: none;
            margin: 0;
            padding-left: 0;
            padding-right: 0;
        }

        legend {
            @include header-size(h3);
            line-height: $header-lineheight;
            color: $header-color;
            font-family: $header-font-family;
            font-style: $header-font-style;
            font-weight: $global-weight-normal;
            margin-top: 10px;
        }
    }

    .company {
        & > div {
            margin-bottom: rem-calc(20);
            display: flex;
            align-items: center;
        }

        i {
            color: $primary-color;
            font-size: rem-calc(32);
            margin-right: rem-calc(20);
            width: rem-calc(30);

            &.fa-envelope {
                font-size: rem-calc(22);
            }
        }

        a {
            font-weight: $bold;
        }
    }

    .contactMap + .breadcrumbsContainer {
        margin-top: 0;
    }

    .contactMap {
        margin-top: 10px;
    }
}
/*#endregion */
